import { $http } from '@/service/http';
import qs from 'qs';
import { type CommonAxiosResponse } from '@/service/types';

const _str = (obj: CommonObjectType): string => qs.stringify(obj);

export const getCountries = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/address/iso/countries',
    data: _str(data ?? {})
  }).get();
};

export const getQiniuKey = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/qiniu/get/client/upload/token',
    data: _str(data != null ? data : {}),
    isLoading: false
  }).post();
};

export const renewApi = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/auth/api/renew',
    data: _str(data != null ? data : {}),
    isLoading: true
  }).get();
};

export const uploadImage = async (
  data: CommonObjectType
): Promise<CommonObjectType> => {
  return await $http({
    url: '/api/admin/image/upload',
    data,
    contentType: 'multipart/form-data',
    onDownloadProgress: (progressEvent: any) => {
      // 进度事件回调
      // const progress = Math.round(
      //   (progressEvent.loaded / progressEvent.total) * 100
      // );
      // console.log(`Download Progress: ${progress}%`);
    }
  }).post();
};

export const getLanguageList = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/i18n/language/find/active/list',
    data: _str(data != null ? data : {}),
    isLoading: false
  }).post();
};

// 管理员解冻因多次密码输错导致账号被封的顾客账号
export const unfreezeRebateAccountDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/account/customer/password/unfreeze',
    data: _str(data ?? {})
  }).post();
};

// 查询解冻记录列表
export const getUnfreezeRecordListDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/account/customer/password/unfreeze/record/find/list',
    data: _str(data ?? {})
  }).post();
};

export const getCustomerDetail = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/account/customer/find/email',
    data: _str(data ?? {})
  }).post();
};
