import { TeamOutlined } from '@ant-design/icons';
import { lazyLoad } from '../util';
import { type MenuRoute } from '../types';

const PromoterList = lazyLoad(
  async () => await import('@/pages/promoter/list')
);
const PromoterEdit = lazyLoad(
  async () => await import('@/pages/promoter/edit')
);
const PromoterStatistics = lazyLoad(
  async () => await import('@/pages/statistics')
);
const UnfreezeList = lazyLoad(
  async () => await import('@/pages/unfreeze/list')
);

export const promoterRoutes: MenuRoute[] = [
  {
    path: '/promoter',
    name: 'menu:promoterManagement',
    key: 'promoter',
    identifier: '/promoter',
    type: 'subMenu',
    icon: <TeamOutlined />,
    routes: [
      {
        path: '/promoter/statistics',
        name: 'menu:promoterStatistics',
        exact: true,
        key: 'promoter:statistics',
        component: PromoterStatistics
      },
      {
        path: '/promoter/list',
        name: 'menu:promoterList',
        exact: true,
        key: 'promoter:list',
        component: PromoterList
      },
      {
        path: '/promoter/edit',
        name: 'menu:editPromoter',
        exact: true,
        key: 'promoter:edit',
        component: PromoterEdit,
        selectedKey: 'promoter:list',
        hideInMenu: true
      },
      {
        path: '/promoter/add',
        name: 'menu:addPromoter',
        exact: true,
        key: 'promoter:add',
        component: PromoterEdit,
        selectedKey: 'promoter:list',
        hideInMenu: true
      },
      {
        path: '/promoter/unfreeze/list',
        name: 'menu:unfreezePromoterAccount',
        exact: true,
        key: 'promoter:unfreeze',
        component: UnfreezeList,
        hideInMenu: false
      }
    ]
  }
];
