import { MoneyCollectOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

// 返佣管理
const rebateProductList = lazyLoad(
  async () => await import('@/pages/rebate/product/list')
);
export const rebateProductDetail = lazyLoad(
  async () => await import('@/pages/rebate/product/detail')
);
const rebateProductSkuList = lazyLoad(
  async () => await import('@/pages/rebate/product/sku/list')
);
const rebateProductSkuEdit = lazyLoad(
  async () => await import('@/pages/rebate/product/sku/edit')
);
const RebateCountryList = lazyLoad(
  async () => await import('@/pages/rebate/country/list')
);
const RebateRecordList = lazyLoad(
  async () => await import('@/pages/rebate/record/list')
);
const RebateRecordDetail = lazyLoad(
  async () => await import('@/pages/rebate/record/detail')
);
// const AddRebateRecord = lazyLoad(
//   async () => await import('@/pages/rebate/record/add')
// );
const RebateBalanceList = lazyLoad(
  async () => await import('@/pages/rebate/balance/list')
);
const RebateAccounttingList = lazyLoad(
  async () => await import('@/pages/rebate/accountting/list')
);
const RebateOperationList = lazyLoad(
  async () => await import('@/pages/rebate/operation/list')
);
const RebateAccountList = lazyLoad(
  async () => await import('@/pages/rebate/account/list')
);
const RebateAccountEdit = lazyLoad(
  async () => await import('@/pages/rebate/account/edit')
);
const UnfreezeList = lazyLoad(
  async () => await import('@/pages/unfreeze/list')
);
const RebateWithdrawalList = lazyLoad(
  async () => await import('@/pages/rebate/withdrawal/list')
);
const RebateWithdrawalDetail = lazyLoad(
  async () => await import('@/pages/rebate/withdrawal/detail')
);

export const rebateRoutes: MenuRoute[] = [
  {
    path: '/rebate',
    name: 'menu:rebateManagement',
    key: 'rebate',
    identifier: '/rebate',
    type: 'subMenu',
    icon: <MoneyCollectOutlined />,
    routes: [
      {
        path: '/rebate/product/list',
        name: 'menu:rebateProductList',
        exact: true,
        key: 'rebate:product:list',
        component: rebateProductList
      },
      {
        path: '/rebate/product/detail',
        name: 'menu:rebateProductDetail',
        exact: true,
        key: 'rebate:product:detail',
        hideInMenu: true,
        selectedKey: 'rebate:product:list',
        component: rebateProductDetail
      },
      {
        path: '/rebate/product/add',
        name: 'menu:addRebateProduct',
        exact: true,
        key: 'rebate:product:add',
        hideInMenu: true,
        selectedKey: 'rebate:product:list',
        component: rebateProductDetail
      },
      {
        path: '/rebate/product/edit',
        name: 'menu:editRebateProduct',
        exact: true,
        key: 'rebate:product:edit',
        hideInMenu: true,
        selectedKey: 'rebate:product:list',
        component: rebateProductDetail
      },
      {
        path: '/rebate/product/sku/list',
        name: 'menu:rebateSkuList',
        exact: true,
        key: 'rebate:product:sku:list',
        component: rebateProductSkuList
      },
      {
        path: '/rebate/product/sku/add',
        name: 'menu:addRebateSku',
        exact: true,
        key: 'rebate:product:sku:add',
        hideInMenu: true,
        selectedKey: 'rebate:product:sku:list',
        component: rebateProductSkuEdit
      },
      {
        path: '/rebate/product/sku/edit',
        name: 'menu:editRebateSku',
        exact: true,
        key: 'rebate:product:sku:edit',
        hideInMenu: true,
        selectedKey: 'rebate:product:sku:list',
        component: rebateProductSkuEdit
      },
      {
        path: '/rebate/record/list',
        name: 'menu:rebateRecordList',
        exact: true,
        key: 'rebate:record:list',
        component: RebateRecordList
      },
      {
        path: '/rebate/record/detail',
        name: 'menu:rebateRecordDetail',
        exact: true,
        key: 'rebate:record:detail',
        hideInMenu: true,
        selectedKey: 'rebate:record:list',
        component: RebateRecordDetail
      },
      {
        path: '/rebate/withdrawal/list',
        name: 'menu:rebateWithdrawalList',
        exact: true,
        key: 'rebate:withdrawal:list',
        component: RebateWithdrawalList
      },
      {
        path: '/rebate/withdrawal/detail',
        name: 'menu:rebateWithdrawalDetail',
        exact: true,
        key: 'rebate:withdrawal:detail',
        hideInMenu: true,
        selectedKey: 'rebate:withdrawal:list',
        component: RebateWithdrawalDetail
      },
      {
        path: '/rebate/country/list',
        name: 'menu:rebateCountryList',
        exact: true,
        key: 'rebate:country:list',
        component: RebateCountryList
      },
      // {
      //   path: '/rebate/record/add',
      //   name: 'menu:rebateRecordAdd',
      //   exact: true,
      //   key: 'rebate:record:add',
      //   component: AddRebateRecord
      // },
      {
        path: '/rebate/balance/list',
        name: 'menu:rebateBalanceList',
        exact: true,
        key: 'rebate:balance:list',
        component: RebateBalanceList
      },
      {
        path: '/rebate/accountting/list',
        name: 'menu:rebateAccounttingList',
        exact: true,
        key: 'rebate:accountting:list',
        component: RebateAccounttingList
      },
      {
        path: '/rebate/operation/list',
        name: 'menu:rebateOperationList',
        exact: true,
        key: 'rebate:operation:list',
        component: RebateOperationList
      },
      {
        path: '/rebate/account/list',
        name: 'menu:rebateAccountList',
        exact: true,
        key: 'rebate:account:list',
        component: RebateAccountList
      },
      {
        path: '/rebate/account/edit',
        name: 'menu:rebateAccountEdit',
        exact: true,
        key: 'rebate:account:edit',
        hideInMenu: true,
        selectedKey: 'rebate:account:list',
        component: RebateAccountEdit
      },
      {
        path: '/rebate/unfreeze/list',
        name: 'menu:unfreezeRebateAccount',
        exact: true,
        key: 'rebate:unfreeze',
        component: UnfreezeList,
        hideInMenu: false
      }
    ]
  }
];
